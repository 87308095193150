import {$fetch} from 'ohmyfetch'

import {useControlStore} from '@/store/control'
import {API_URL} from '@/constants'
import {useAuthStore} from '@/store/auth'
import {useRemoveNullUndefined} from '@/composables'
import {IObject} from '@/types'

let refreshTokenFlag = false

export const apiFetch = $fetch.create({
  baseURL: API_URL,

  async onRequest({options}) {
    const controlStore = useControlStore()
    const authStore = useAuthStore()

    const headers: Headers = new Headers()

    // auth token
    if (authStore.token) {
      headers.set('token', authStore.token)
    }

    if (authStore.deviceType) {
      headers.set('deviceType', authStore.deviceType)
    }

    if (authStore.deviceId) {
      headers.set('deviceId', authStore.deviceId)
    }
    options.headers = headers

    // normalize params
    options.params = options.params ? useRemoveNullUndefined(options.params as IObject) : {}

    controlStore.$patch({loading: true})
  },

  async onResponse(response: any) {
    const controlStore = useControlStore()

    if (response.response._data.commons?.settings) {
      const authStore = useAuthStore()
      await authStore.setSettings(response.response._data.commons.settings)
    }
    controlStore.$patch({loading: false})
  },

  async onResponseError({request, response, options}) {
    const controlStore = useControlStore()
    const authStore = useAuthStore()

    const error = response._data.data.message

    const headers: Headers = new Headers()

    switch (response.status) {
      case 400:
        controlStore.$patch({error})
        break

      case 401:
        if (refreshTokenFlag) {
          refreshTokenFlag = false
          await authStore.localLogout()
          break
        }

        refreshTokenFlag = true
        await authStore.refresh()

        if (authStore.token) {
          headers.set('token', authStore.token)
        }
        options.headers = headers
        await apiFetch(request, options)
        break
      case 418:
        controlStore.$patch({error})
        break
      case 500:
        break;
      case 504:
        controlStore.$patch({error: 'Internal server error'})
        break
    }

    controlStore.$patch({loading: false})
  }
})
